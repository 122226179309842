import MarketplaceDashboardPanel from "@/components/MarketplaceDashboardPanel";
import MembershipDashboardPanel from "@/components/MembershipDashboardPanel";
import useGetDirectToDoctorPrimarySymptoms from "@/hooks/useGetDirectToDoctorPrimarySymptoms";
import useGetMemberships from "@/hooks/useGetMemberships";
import {
  getMembershipConfig,
  MemberProfile,
} from "@/services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import theme, { shadows } from "@/theme";
import { convertPxToRem } from "@/utils";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  BenefitsTile,
  HealthCheckTile,
  HealthProfileTile,
  MyPeopleTile,
} from "../DashboardTiles";
import LifestyleAndAdviceSection from "../LifestyleAndAdviceSection";
import MessagesCTA from "../MessagesCTA";
import PayGMedicalAssistanceCTA from "./components/PayGMedicalAssistanceCTA";
import DirectToDoctorSlider from "./components/DirectToDoctorSlider";

interface PayGMemberDashboardProps {
  memberProfile: MemberProfile | null;
  isMemberProfileLoading: boolean;
  memberProfileFetchError: boolean;
}

export default function PayGMemberDashboard({
  memberProfile,
  isMemberProfileLoading,
  memberProfileFetchError,
}: PayGMemberDashboardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isIntercareSupported, setIsIntercareSupported] = useState<
    boolean | null
  >(null);

  const {
    firstMembership,
    memberships,
    membershipsFetchError,
    isMembershipsLoading,
  } = useGetMemberships();

  function onBellIconButtonClick() {
    navigate("/home/messages");
  }

  const {
    primarySymptoms,
    primarySymptomsFetchError,
    isPrimarySymptomsLoading,
  } = useGetDirectToDoctorPrimarySymptoms();

  const [
    isDirectToDoctorPrimarySymptomsModalOpen,
    setIsDirectToDoctorPrimarySymptomsModalOpen,
  ] = useState(false);

  function onViewAllPrimarySymptomsClick() {
    setIsDirectToDoctorPrimarySymptomsModalOpen(true);
  }

  useEffect(() => {
    if (isMemberProfileLoading === false) {
      const membershipConfig = getMembershipConfig(
        memberProfile?.membership?.productCode
      );

      setIsIntercareSupported(
        (isFeatureEnabled(
          FeatureToggleIdentifier.ENABLE_INTERCARE_MEDICAL_ASSISTANCE
        ) &&
          membershipConfig?.isIntercareSupported) ||
          false
      );
    }
  }, [memberProfile, isMemberProfileLoading]);

  return (
    <>
      <Box
        sx={{
          borderRadius: `0 0 ${convertPxToRem(15)}  ${convertPxToRem(15)}`,
          background: `linear-gradient(to top, ${theme.palette.primary.contrastText}, ${theme.palette.accent2.light}, ${theme.palette.accent2.light})`,
          position: "absolute",
          height: convertPxToRem(350),
          width: "100%",
          left: 0,
          top: 0,
          zIndex: 1,
          boxShadow: shadows.medium,
        }}
      />

      <Stack sx={{ zIndex: 2 }} spacing={1}>
        {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_IN_APP_MESSAGING) && (
          <Stack display="flex" alignItems="end" position="relative">
            <MessagesCTA onClick={onBellIconButtonClick} />
          </Stack>
        )}
        <Stack spacing={1}>
          <Typography
            variant="body1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(20),
            }}
          >
            {memberProfile && memberProfile?.firstName.length > 0
              ? t("Home.title.withName", {
                  name: memberProfile.firstName,
                })
              : t("Home.title.withoutName")}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(24),
            }}
          >
            {t("Home.helpSubtitle")}
          </Typography>
          <Stack>
            <Stack sx={{ zIndex: 2 }}>
              {isIntercareSupported === true && (
                <PayGMedicalAssistanceCTA
                  memberProfile={memberProfile}
                  isLoading={isMemberProfileLoading}
                />
              )}
            </Stack>
          </Stack>

          {!isPrimarySymptomsLoading &&
            !primarySymptomsFetchError &&
            primarySymptoms?.length > 0 && (
              <>
                <Stack
                  mt={2}
                  spacing={2}
                  sx={{
                    width: "106%",
                    [theme.breakpoints.down("md")]: {
                      width: "100vw",
                    },
                    position: "relative",
                    left: `${convertPxToRem(-16)}`,
                  }}
                >
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    sx={{
                      padding: `0 ${convertPxToRem(16)}`,
                    }}
                  >
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography fontWeight="bold" component="h2">
                        {t("DirectToDoctor.primarySymptoms.homescreen.title")}
                      </Typography>
                      <Button
                        size="small"
                        variant="text"
                        sx={{ fontSize: convertPxToRem(14) }}
                        onClick={onViewAllPrimarySymptomsClick}
                      >
                        {t("DirectToDoctor.primarySymptoms.homescreen.CTA")}
                      </Button>
                    </Stack>
                  </Stack>
                  <DirectToDoctorSlider
                    primarySymptoms={primarySymptoms}
                    isModalOpen={isDirectToDoctorPrimarySymptomsModalOpen}
                    setIsModalOpen={setIsDirectToDoctorPrimarySymptomsModalOpen}
                  />
                </Stack>
              </>
            )}
        </Stack>
        <Stack spacing={2}>
          <Typography component="h2" fontWeight={600}>
            {t("TiledQuickLinks.title")}
          </Typography>

          <Stack
            direction="row"
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
            gap={2}
          >
            <HealthCheckTile
              memberProfile={memberProfile}
              isLoading={isMemberProfileLoading}
              shouldShowServiceUsage={true}
            />
            <HealthProfileTile
              lastUpdatedDate={memberProfile?.healthProfile?.lastUpdatedAt}
              healthScore={
                Number(memberProfile?.healthProfile?.healthScoreValue) || 0
              }
              isLoading={isMemberProfileLoading}
              isError={memberProfileFetchError}
            />
            <BenefitsTile
              benefitCount={memberProfile?.benefitCount}
              isLoading={isMemberProfileLoading}
              isError={memberProfileFetchError}
            />
            <MyPeopleTile
              membershipId={memberProfile?.membership.id}
              dependentsCount={memberProfile?.dependentCount}
              isLoading={isMemberProfileLoading}
              isError={memberProfileFetchError}
            />
          </Stack>
          {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_CHAT_BOT) && (
            <LifestyleAndAdviceSection />
          )}
        </Stack>
      </Stack>
      <Stack mt={4} spacing={5}>
        <MembershipDashboardPanel
          memberProfile={memberProfile}
          membershipData={{
            firstMembership,
            memberships,
            membershipsFetchError,
            isMembershipsLoading,
          }}
        />

        <MarketplaceDashboardPanel />
      </Stack>
    </>
  );
}
