import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Product } from "../../services/core-api-adapter";
import { convertPxToRem } from "../../utils";

interface ProductPartnerListProps {
  product: Product;
}

const partnerLogoStyling = {
  height: "16px",
  maxWidth: "100%",
};

export default function ProductPartnerList({
  product,
}: ProductPartnerListProps) {
  const { t } = useTranslation();

  let provider = null;
  if ("provider" in product) {
    provider = product.provider;
  }
  if ("productProvider" in product) {
    provider = product.productProvider;
  }

  return (
    <>
      {provider && (
        <Stack
          spacing={0.75}
          alignItems="flex-start"
          aria-label={t("MembershipCard.providerLogoLabel")}
        >
          <Typography
            variant="h5"
            fontSize={convertPxToRem(8)}
            fontWeight={"normal"}
            color="neutral.500"
          >
            {t("MembershipCard.providerLogoLabel")}
          </Typography>
          <img
            src={provider.imageUri}
            alt={provider.name}
            style={{
              ...partnerLogoStyling,
            }}
          />
        </Stack>
      )}

      {product?.underwriter?.id && (
        <Stack
          spacing={0.75}
          alignItems="center"
          aria-label={t("MembershipCard.underwriterLogoLabel")}
        >
          <Typography
            variant="h5"
            fontSize={convertPxToRem(8)}
            fontWeight={"normal"}
            color="neutral.500"
          >
            {t("MembershipCard.underwriterLogoLabel")}
          </Typography>
          <img
            style={{
              ...partnerLogoStyling,
            }}
            src={`/components/MembershipCard/logos/${product?.underwriter?.id}.png`}
            alt={product.underwriter.name}
          />
        </Stack>
      )}

      {product?.administrator?.id && (
        <Stack
          spacing={0.75}
          alignItems="flex-end"
          aria-label={t("MembershipCard.administratorLogoLabel")}
        >
          <Typography
            variant="h5"
            fontSize={convertPxToRem(8)}
            fontWeight={"normal"}
            color="neutral.500"
          >
            {t("MembershipCard.administratorLogoLabel")}
          </Typography>
          <img
            style={{
              ...partnerLogoStyling,
            }}
            src={`/components/MembershipCard/logos/${product?.administrator?.id}.png`}
            alt={product.administrator.name}
          />
        </Stack>
      )}
    </>
  );
}
