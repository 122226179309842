import { Trans, useTranslation } from "react-i18next";
import SlideUpDialog from "../../../../../SlideUpDialog";
import { Stack, Typography } from "@mui/material";
import { convertPxToRem } from "../../../../../../utils";
import ItemPill from "../../../../../ItemPill";
import { DirectToDoctorPrimarySymptom } from "../../../../../../services/core-api-adapter";
import { trackUserInteraction } from "@/services/analytics-adapter";

interface DirectToDoctorPrimarySymptomsModalProps {
  isOpen: boolean;
  onDialogClose: () => void;
  primarySymptoms: DirectToDoctorPrimarySymptom[];
  onPrimarySymptomClick: (primarySymptom: DirectToDoctorPrimarySymptom) => void;
  itemMap: Record<string, any>;
}

export default function DirectToDoctorPrimarySymptomsModal({
  isOpen,
  onDialogClose,
  primarySymptoms,
  onPrimarySymptomClick,
  itemMap,
}: DirectToDoctorPrimarySymptomsModalProps) {
  const { t } = useTranslation();

  function onPrimarySymptomPillClick(
    primarySymptom: DirectToDoctorPrimarySymptom
  ) {
    trackUserInteraction({
      linkText: `DirectToDoctorPrimarySymptomsModal | ${primarySymptom.name}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    onPrimarySymptomClick(primarySymptom);
  }

  return (
    <>
      <SlideUpDialog
        isOpen={isOpen}
        onClose={onDialogClose}
        label={t("DirectToDoctor.primarySymptoms.modal.label")}
      >
        <Stack textAlign="center" spacing={3}>
          <Typography variant="h2">
            {t("DirectToDoctor.primarySymptoms.modal.title")}
          </Typography>
          <Typography variant="h4" fontWeight={400}>
            <Trans i18nKey="DirectToDoctor.primarySymptoms.modal.subtitle"></Trans>
          </Typography>
          <Stack spacing={2}>
            {primarySymptoms.map((primarySymptom) => (
              <ItemPill
                key={primarySymptom.id}
                onClick={() => onPrimarySymptomPillClick(primarySymptom)}
                itemMap={itemMap}
                selectedItem={primarySymptom}
                titleStyles={{
                  fontSize: convertPxToRem(16),
                  fontWeight: 600,
                }}
                shouldShowSubtitle={true}
                shouldShowArrow={true}
              />
            ))}
          </Stack>
        </Stack>
      </SlideUpDialog>
    </>
  );
}
