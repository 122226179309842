import IconLoader from "@/components/IconLoader";
import ItemPillSlider from "@/components/ItemPillSlider";
import { DirectToDoctorPrimarySymptom } from "@/services/core-api-adapter";
import { convertPxToRem } from "@/utils";
import { useNavigate } from "react-router";
import DirectToDoctorPrimarySymptomsModal from "../DirectToDoctorPrimarySymptomsModal";
import { trackUserInteraction } from "@/services/analytics-adapter";

interface DirectToDoctorSliderProps {
  primarySymptoms: DirectToDoctorPrimarySymptom[];
  isModalOpen: boolean;
  setIsModalOpen: (arg: boolean) => void;
}

interface PrimarySymptomPillMap {
  [key: string]: any;
}

const primarySymptomIconStyles = {
  width: convertPxToRem(36),
  height: convertPxToRem(36),
};

const primarySymptomPillMap: PrimarySymptomPillMap = {
  SKIN_CONDITIONS: {
    title: "DirectToDoctor.primarySymptoms.skinConditions.title",
    subtitle: "DirectToDoctor.primarySymptoms.skinConditions.subtitle",
    icon: (
      <IconLoader icon="SkinConditionsIcon" sx={primarySymptomIconStyles} />
    ),
  },
  EYE_INFECTIONS: {
    title: "DirectToDoctor.primarySymptoms.eyeInfection.title",
    subtitle: "DirectToDoctor.primarySymptoms.eyeInfection.subtitle",
    icon: <IconLoader icon="EyeInfectionIcon" sx={primarySymptomIconStyles} />,
  },
  BLADDER_INFECTION: {
    title: "DirectToDoctor.primarySymptoms.bladderInfection.title",
    subtitle: "DirectToDoctor.primarySymptoms.bladderInfection.subtitle",
    icon: (
      <IconLoader icon="BladderInfectionIcon" sx={primarySymptomIconStyles} />
    ),
  },
  COLD_FLU: {
    title: "DirectToDoctor.primarySymptoms.coldFlu.title",
    subtitle: "DirectToDoctor.primarySymptoms.coldFlu.subtitle",
    icon: <IconLoader icon="ColdAndFluIcon" sx={primarySymptomIconStyles} />,
  },
  VOMITING_DIARRHEA: {
    title: "DirectToDoctor.primarySymptoms.vomitingDiarrhoea.title",
    subtitle: "DirectToDoctor.primarySymptoms.vomitingDiarrhoea.subtitle",
    icon: (
      <IconLoader
        icon="VomitingAndDiarrhoeaIcon"
        sx={primarySymptomIconStyles}
      />
    ),
  },
  HEADACHE: {
    title: "DirectToDoctor.primarySymptoms.headache.title",
    subtitle: "DirectToDoctor.primarySymptoms.headache.subtitle",
    icon: <IconLoader icon="HeadacheIcon" sx={primarySymptomIconStyles} />,
  },
  BACK_PAIN: {
    title: "DirectToDoctor.primarySymptoms.backPain.title",
    subtitle: "DirectToDoctor.primarySymptoms.backPain.subtitle",
    icon: <IconLoader icon="BackPainIcon" sx={primarySymptomIconStyles} />,
  },
  CONTRACEPTION: {
    title: "DirectToDoctor.primarySymptoms.contraception.title",
    subtitle: "DirectToDoctor.primarySymptoms.contraception.subtitle",
    icon: <IconLoader icon="ContraceptionIcon" sx={primarySymptomIconStyles} />,
  },
  GENITAL_INFECTIONS: {
    title: "DirectToDoctor.primarySymptoms.genitalInfections.title",
    subtitle: "DirectToDoctor.primarySymptoms.genitalInfections.subtitle",
    icon: (
      <IconLoader
        icon="SexualTransmittedDiseasesIcon"
        sx={primarySymptomIconStyles}
      />
    ),
  },
  GENERAL_CONDITIONS: {
    title: "DirectToDoctor.primarySymptoms.generalConditions.title",
    subtitle: "DirectToDoctor.primarySymptoms.generalConditions.subtitle",
    icon: (
      <IconLoader
        icon="GeneralDoctorConsultIcon"
        sx={primarySymptomIconStyles}
      />
    ),
  },
};

export default function DirectToDoctorSlider({
  primarySymptoms,
  isModalOpen,
  setIsModalOpen,
}: DirectToDoctorSliderProps) {
  const navigate = useNavigate();

  function onPrimarySymptomClick(primarySymptom: DirectToDoctorPrimarySymptom) {
    navigate(
      `/home/get-medical-assistance/intercare?primarySymptomIds=${primarySymptom.id}`
    );
  }

  function handleTrackInteraction(item: DirectToDoctorPrimarySymptom) {
    trackUserInteraction({
      linkText: `DirectToDoctorPrimarySymptomsSlider | ${item.name}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  return (
    <>
      <ItemPillSlider
        items={primarySymptoms}
        itemMap={primarySymptomPillMap}
        onItemClick={onPrimarySymptomClick}
        trackInteraction={handleTrackInteraction}
      />

      <DirectToDoctorPrimarySymptomsModal
        primarySymptoms={primarySymptoms}
        itemMap={primarySymptomPillMap}
        onPrimarySymptomClick={onPrimarySymptomClick}
        isOpen={isModalOpen}
        onDialogClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
